export default {
  'has-dark-brown-color': '#724938',
  'dirt-brown': '#563f2e',
  'dirt-brown-70%': 'rgba(86,63,46,0.7)',
  'very-light-pink': '#f1eceb',

  black: '#000000',
  white: '#ffffff',
  purple: '#6a318e',
  'bright-purple': '#6924d6',
  'black-p': '#363636',
  'slime-green': '#abcd03',
  'bright-pink': '#ff0080',
};
