/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import colors from '../config/color';
import RingHeader from './ringHeader';
import './layout.css';
import { FaLine, FaFacebookSquare } from 'react-icons/fa';
import Hero from '../components/hero';
import MenuButton from '../components/menuButton';

const Layout = ({
  children,
  title,
  showHeader = false,
  showHero = false,
  showAccountBtn = true,
  showHamburgerBtn = true,
  breadcrumbs,
  contentStyle = {},
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // console.log(
  //   // eslint-disable-next-line no-undef
  //   `${process.env.GATSBY_BUILD_NUMBER}.${process.env.GATSBY_BUILD_DATE}.${process.env.GATSBY_GIT_HASH}`,
  // );

  return (
    <>
      {/* <MenuButton /> */}
      {showHeader ? (
        <RingHeader
          siteTitle={title || data.site.siteMetadata.title}
          breadcrumbs={breadcrumbs}
          showAccountBtn={showAccountBtn}
          showHamburgerBtn={showHamburgerBtn}
        />
      ) : null}
      {showHero ? <Hero /> : null}
      <div className="main-content" style={contentStyle}>
        <main>{children}</main>
      </div>

      {/* footer 樣式 */}
      <footer>
        <p>COPYRIGHT © 2020 BAMBOO Technology LTD. ALL RIGHTS RESERVED.</p>
        <div className="spec-content">
          <a href="#">使用規範</a>
          <p className="divider">|</p>
          <a href="#">隱私規範</a>
        </div>
      </footer>

      <style jsx>{`
        footer {
          padding: 16px;
          background-color: ${colors['slime-green']};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .main-content {
          background-color: #fff;
          min-height: calc(100vh - 130px);

          // background-position: center;
          // background-repeat: no-repeat;
          // background-size: cover;
          // background-image: url("/images/background.jpg");
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.05px;
        }

        a {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 8px;
          text-decoration: none;
        }

        .spec-content {
          display: flex;
          flex-direction: row;
          margin-top: 7px;
        }

        .divider {
          margin: 0 6px;
        }

        @media (min-width: 768px) {
          footer {
            padding: 40px 60px;
            flex-direction: row;
            justify-content: space-between;
          }

          p {
            font-size: 18px;
            line-height: 22px;
          }

          a {
            font-size: 18px;
            line-height: 22px;
          }

          .divider {
            margin: 0 20px;
          }
        }
      `}</style>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.array,
};

Layout.defaultProps = {
  breadcrumbs: [],
};

export default Layout;
