import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const RingHeader = ({ showAccountBtn = true, showHamburgerBtn = true }) => {
  return (
    <header>
      <div className="container">
        <div className="content">
          {showHamburgerBtn ? (
            <img className="bar" src="/images/ic_hamburger.png"></img>
          ) : null}
          <img src="/images/logo.png" />
          <div className="list-content">
            {/* <a href="#">Why emotions matters</a>
            <a href="#">Why Here Hear</a>
            <a href="#">How Hear Hear works</a>
            <a href="/latest">Latest</a> */}
          </div>
          {showAccountBtn ? (
            <a href="/account/login">
              <img src="/images/ic_account.png" />
            </a>
          ) : (
            <div></div>
          )}
        </div>
        <div className="mask"></div>
      </div>

      <style jsx>{`
        .container {
          height: 60px;
          background: rgba(255, 255, 255, 0.5);
          padding: 0 16px;
          position: relative;
          backdrop-filter: blur(10px);
        }

        .content {
          height: 60px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .list-content {
          display: none;
        }



        @media (min-width: 768px) {
          .container {
            padding: 0 60px;
          }

          .bar {
            display: none;
          }

          .list-content {
            display: flex;
            justify-content: flex-end;
            
          }

          a {
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Regular;
            font-size: 21px;
            text-decoration: none;
            margin-left: 40px;
          }
        @media (min-width: 1200px) {
        }
      `}</style>
    </header>
  );
};

RingHeader.propTypes = {
  title: PropTypes.string,
  textStyle: PropTypes.object,
};

RingHeader.defaultProps = {
  title: '',
  textStyle: {},
};

export default RingHeader;
